<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Vue from "vue";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Ajouter un dons",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Dons",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      showPassword: false,
      phoneNumber: "",
      thenDay: "",
      form: {
        lastname: "",
        firstname: "",
        gender: "",
        state: "",
        zipcode: "",
        phoneNumber: "",
      },
      Edit: {
        titre: "",
        soustitre: "",
        url_donplan: "",
      },
      donImage: null,
      donDes:[],
      donActif:[],
     
     
      typeform: {
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        url: "",
        digit: "",
        number: "",
        alphanum: "",
        textarea: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      file: null,
      donForm: {
        titre: "",
        soustitre: "",
        visible: true,
      },
      dons: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "titre", sortable: true, label: "Titre" },
        { key: "date", sortable: true },
        { key: "adminId", sortable: true, label: "Publié par" },
        { key: "action" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showactive:false,
      showdisable: false,
      donsrow: null,
      totalDonActif:0,
      totalDonInActif:0,
    };
  },
  validations: {
  
 
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },
  },

  async mounted() {
    this.init();
  },

  methods: {
    async init() {
      const listeDons = await apiRequest("GET", "socials", undefined, false);

      if (listeDons && listeDons.data) {
        const donFormatTable = listeDons.data.map((don) => {
          return {
            id: don.id,
            titre: don.titre,
            soustitre: don.message,
            date: new Date(don.dateCreation).toLocaleString("fr-FR"),
            adminId:don.admin !=null? don.admin.lastname + " " + don.admin.firstname:"",
            actif: don.isActif,
          };
        });

        this.dons = donFormatTable;

      this.donDes=[];
      this.donActif=[];
      
      for(let i=0;i<this.dons.length;i++){
        if(this.dons[i].actif==true){
         this.donActif.push(this.dons[i])
         this.totalDonActif=this.donActif.length
        }else{
          this.donDes.push(this.dons[i])
          this.totalDonInActif=this.donDes.length
        }
      }
      }
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    ModifFileUpload() {
      this.donImage = this.$refs.image.files[0];
    },
    positionAdd() {
      Vue.swal({
        positionAdd: "center-end",
        icon: "success",
        title: "Ajout éffectué avec succès",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée avec succès!!",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    async submitDon() {
      let formData = new FormData();
      formData.append("titre", this.donForm.titre);
      formData.append("message", this.donForm.soustitre);
      formData.append("file", this.file);
      const returnBonPlan = await apiRequest("POST", "social", formData, true);
      if (returnBonPlan && returnBonPlan.data) {
        this.positionAdd();
        this.init();
      }
    },

    async desactiverDons() {
      this.showdisable = false;
      const ServicedesactiverDons = await apiRequest(
        "POST",
        "social-off",
        {
          id: this.donsrow.id,
          isActif: false,
        },
        false
      );

      if (ServicedesactiverDons && ServicedesactiverDons.data) {
        this.position();
        this.init();
      }
    },

    desactiver(row) {
      this.showdisable = true;
      this.donsrow = row;
    },

    activerPlan(row){
     this.showactive=true
     this.donsrow=row
    },

    async supprimerDons() {
      this.showDelete = false;
      const ServicesupprimerDons = await apiRequest(
        "POST",
        "social-supp",
        { id: this.donsrow.id },
        false
      );

      if (ServicesupprimerDons && ServicesupprimerDons.data) {
     
        this.$toast.success("Opération effectuée avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
        this.init();
      }
    },


    goToUserDetail(id) {
      window.open(`/user/user_info/${id}`, '_blank','noreferrer');
    },
  
  async activerPlans(){
      this.showactive = false;
      const ServicedesactiverDons = await apiRequest(
        "POST",
        "social-off",
        {
          id: this.donsrow.id,
          isActif: true,
        },
        false
      );

      if (ServicedesactiverDons && ServicedesactiverDons.data) {
        this.position();
        this.init();
      }
  },
    supprimer(row) {
      this.showDelete = true;
      this.donsrow = row;
    },

    async editerDons() {
      this.showEdit = false;
      let formData = new FormData();
      formData.append("id", this.donsrow.id);
      formData.append("titre", this.Edit.titre);
      formData.append("message", this.Edit.soustitre);
      formData.append("file", this.donImage);
      formData.append("isActif", this.donsrow.actif);
      const returnDons = await apiRequest("PUT", "social", formData, true);
      if (returnDons && returnDons.data) {
        this.init();
      }
    },

    editer(row) {
      this.showEdit = true;
      this.donsrow = row;
      (this.Edit.titre = row.titre), (this.Edit.soustitre = row.soustitre);
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.formSubmit();
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },


    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <form enctype="multipart/form-data" @submit.prevent="submitDon">
              <div class="form-group">
                <label for="tite">Titre</label>
                <input
                  type="text"
                  id="title"
                  class="form-control"
                  placeholder="Titre"
                  v-model="donForm.titre"
                />
              </div>
              <div class="form-group">
                <label for="tite">Message</label>
                <input
                  type="text"
                  id="title"
                  class="form-control"
                  placeholder="Sous-titre"
                  v-model="donForm.soustitre"
                />
              </div>
              <div class="form-group">
                <label for="tite">Image</label>
                <input
                  type="file"
                  id="file"
                  ref="file"
                  v-on:change="handleFileUpload"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <button class="btn btn-primary float-right">Ajouter</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Gérer</h5>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des dons</a>
                  </template>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                      >
                        <label class="d-inline-flex align-items-center">
                          Rechercher:
                          <b-form-input
                            v-model="filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="donActif"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"


                    >


                    <template v-slot:cell(user)="row">
                      <span>
                        {{ row.item }}
                      </span>

                    </template>
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class="text-primary"
                          v-b-tooltip.hover
                          @click="editer(row.item)"
                          title="Editer"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          v-b-tooltip.hover
                          @click="desactiver(row.item)"
                          title="Désactiver"
                        >
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          @click="supprimer(row.item)"
                          v-b-tooltip.hover
                          title="supprimer"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalDonActif"
                            :per-page="perPage"
                            :rows="donActif"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des dons désactivés</a>
                  </template>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                      >
                        <label class="d-inline-flex align-items-center">
                          Rechercher:
                          <b-form-input
                            v-model="filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="donDes"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                        
                        <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="activerPlan(row.item)"
                        title="Activer"
                      >
                        <i class="mdi mdi-check font-size-18"></i>
                      </a>
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          @click="supprimer(row.item)"
                          v-b-tooltip.hover
                          title="supprimer"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalDonInActif"
                            :per-page="perPage"
                            :row="donDes"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer ce don ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <button class="btn btn-success" @click="showDelete = false">Non</button>
        <button class="btn btn-danger mr-4 ml-3" @click="supprimerDons">
          Oui
        </button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" 
      centered 
      v-model="showactive" 
      title="Voulez-vous vraiment activer bon plan ?" 
      title-class="font-18"
      hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showactive=false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activerPlans">Oui</b-button>
      </div>
      
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer utilisateur"
      title-class="font-18"
      hide-footer
    >
      <form @submit.prevent="editerDons">
        <div class="form-group">
          <label for="tite">Titre</label>
          <input
            type="text"
            id="title"
            v-model="Edit.titre"
            class="form-control"
            placeholder="titre"
          />
        </div>
        <div class="form-group">
          <label for="tite">Message</label>
          <input
            type="text"
            id="title"
            class="form-control"
            v-model="Edit.soustitre"
            placeholder="titre"
          />
        </div>
        <div class="form-group">
          <label for="tite">image</label>
          <input
            type="file"
            id="image"
            ref="image"
            v-on:change="ModifFileUpload"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary float-right">Modifier</button>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment désactiver ce don ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <button class="btn btn-success" @click="showdisable = false">
          Non
        </button>
        <button class="btn btn-danger mr-4 ml-3" @click="desactiverDons">
          Oui
        </button>
      </div>
    </b-modal>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}
</style>
